import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delivery"
export default class extends Controller {
  static targets = ["delivered_input", "received_input", "unbalance_jar", "product_price", "product_cost_label", "delivery_status"]

  connect() {
    this.update_price_label()
  }

  handleChange(event) {
    const value = event.target.value;
    if (value !== '') {
      const txnType = event.target.dataset.deliveryTarget.split('_')[0];
      const initialValue = parseInt(event.target.dataset.initialValue);
      debugger
      if (parseInt(value) > initialValue) {
        this[`${txnType}Increment`](false);
      } else {
        this[`${txnType}Decrement`](false);
      }
    }
  }

  deliveredIncrement(canStepChange = true) {
    if (canStepChange)
    this.delivered_inputTarget.stepUp()

    this.update_price_label()
    this.validate_product_quantity()
  }

  deliveredDecrement(canStepChange = true) {
    if (canStepChange)
    this.delivered_inputTarget.stepDown()

    this.update_price_label()
    this.validate_product_quantity()
  }

  receivedIncrement(canStepChange = true) {
    let unbalance_jar_quantity = parseInt(this.unbalance_jarTarget.dataset.productUnbalanceQty)
    let is_delivery_done = this.delivery_statusTarget.value

    let delivered_quantity = parseInt(this.delivered_inputTarget.value)
    let received_quantity = parseInt(this.received_inputTarget.value)

    let delivered_initial_quantity = parseInt(this.delivered_inputTarget.dataset.initialValue)
    let received_initial_quantity = parseInt(this.received_inputTarget.dataset.initialValue)
    if (canStepChange){
      if ( is_delivery_done != "true" && received_quantity < unbalance_jar_quantity + delivered_quantity )
      { this.received_inputTarget.stepUp() }
      else if ( is_delivery_done == "true"  && received_quantity < unbalance_jar_quantity + received_initial_quantity+ (delivered_quantity-delivered_initial_quantity)) {
        { this.received_inputTarget.stepUp() }
      }
    }
  }

  receivedDecrement(canStepChange = true) {
    if (canStepChange)
    this.received_inputTarget.stepDown()

    this.validate_product_quantity()
  }

  update_price_label() {
    let product_quantity = parseInt(this.delivered_inputTarget.value)
    let price = parseFloat(this.product_priceTarget.dataset.productPrice)
    if ( product_quantity > 0 )
      this.product_cost_labelTarget.innerHTML = `Product Cost (${product_quantity} * &#8377 ${price} ) = &#8377  ${ product_quantity * price }`
    else
    this.product_cost_labelTarget.innerHTML = ""
  }

  validate_product_quantity() {
    const receivedInputTarget = this.element.querySelector('[data-delivery-target="received_input"]');
    if (receivedInputTarget) {
      let unbalance_jar_quantity = parseInt(this.unbalance_jarTarget.dataset.productUnbalanceQty)
      let is_delivery_done = this.delivery_statusTarget.value

      let delivered_quantity = parseInt(this.delivered_inputTarget.value)
      let received_quantity = parseInt(this.received_inputTarget.value)

      let delivered_initial_quantity = parseInt(this.delivered_inputTarget.dataset.initialValue)
      let received_initial_quantity = parseInt(this.received_inputTarget.dataset.initialValue)

      if (is_delivery_done != "true") {
        if (unbalance_jar_quantity + delivered_quantity >= received_quantity){
          this.received_inputTarget.classList.remove('input-error', 'border-2')
          this.received_inputTarget.classList.add('input-primary')
        } else {
          this.received_inputTarget.classList.add('input-error', 'border-2')
          this.received_inputTarget.classList.remove('input-primary')
        }
      } else {
          if (received_quantity <= unbalance_jar_quantity + received_initial_quantity+ (delivered_quantity-delivered_initial_quantity)) {
            this.received_inputTarget.classList.remove('input-error', 'border-2')
            this.received_inputTarget.classList.add('input-primary')
          } else {
            this.received_inputTarget.classList.add('input-error', 'border-2')
            this.received_inputTarget.classList.remove('input-primary')
          }
      }
    }
  }
}