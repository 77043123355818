import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sms-settings"
export default class extends Controller {
  static targets = ['input']
  
  connect() {
    const id = this.element.querySelector('#send_invoice_ready_msg_yes').checked 
      ? 'send_invoice_ready_msg_yes' 
      : 'send_invoice_ready_msg_no';
    this.toggleDayField({ target: { id } });
  }

  increment(){
    const inputTarget = this.inputTarget;
    if(inputTarget.value == ''){
      inputTarget.value = 0;
    }
    inputTarget.stepUp();
    this.validate();
  }

  decrement(){
    const inputTarget = this.inputTarget;
    if(inputTarget.value == ''){
      inputTarget.value = 1;
    }
    inputTarget.stepDown();
    this.validate();
  }

  validate(){
    const inputTarget = this.inputTarget;
    if ( parseInt(inputTarget.value) >= 0){
      inputTarget.classList.remove("border-red-500");
    } else {
      inputTarget.classList.add("border-red-500");
    }
  }

  toggleDayField(event){
    const dayContainer  = this.element.querySelector('#day-container');
    if (event.target.id.slice(-2) == 'no'){
      dayContainer.classList.add('hidden');
    } else {
      dayContainer.classList.remove('hidden');
    }
  }
}
