import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="color-changer"
export default class extends Controller {
  static targets = [ "changeColorInput", "divContainer", "quantityChangedLabel", "isQuantityChanged"]
  connect() {
  }

  increment_change(){
    const inputTargets = this.changeColorInputTargets;
    let count=0;
    for (const el of this.changeColorInputTargets) {
      let { initialValue, isProductDelivered, isProductReceived } = el.dataset;
      let current_value = parseInt(el.value);
      if (isProductDelivered == "true" || isProductReceived=="true") {
        if (current_value != parseInt(initialValue)) {
          this.divContainerTarget.classList.add('border-2', 'border-l-4', 'border-solid', 'border-yellow-500');
          this.quantityChangedLabelTarget.classList.remove('hidden');
          this.isQuantityChangedTarget.value = true
          break;
        } else {
          count++;
        }
      } else {
        if (current_value =! parseInt(initialValue)) {
          this.divContainerTarget.classList.remove( 'border-red-500');
          this.divContainerTarget.classList.add('border-green-500');
          this.isQuantityChangedTarget.value = ""
          break;
        }

      }

    }
    if (count == inputTargets.length) {
      this.divContainerTarget.classList.remove('border-2', 'border-l-4', 'border-solid', 'border-yellow-500');
      this.divContainerTarget.classList.add('input-primary');
      this.quantityChangedLabelTarget.classList.add('hidden');
      this.isQuantityChangedTarget.value = ""
    }
  }

  decrement_change(){
    let count=0;
    const inputTargets = this.changeColorInputTargets;
    const isProductDelivered = this.changeColorInputTarget.dataset.isProductDelivered;
    const isProductReceived = this.changeColorInputTarget.dataset.isProductReceived;
    if (isProductDelivered == "true" || isProductReceived=="true") {
      inputTargets.forEach(el => {
        const initialValue = el.dataset.initialValue;
        let current_value = parseInt(el.value);
        if (current_value != parseInt(initialValue)) {
          this.divContainerTarget.classList.add('border-2', 'border-l-4', 'border-solid', 'border-yellow-500');
          this.quantityChangedLabelTarget.classList.remove('hidden');
          this.isQuantityChangedTarget.value = true
        } else {
          count++;
        }
      })

    } else {
      if (inputTargets.every(el => parseInt(el.value) === 0)) {
        this.divContainerTarget.classList.add('border-2', 'border-l-4', 'border-solid', 'border-red-500');
        this.isQuantityChangedTarget.value = true;
      }
    }

    if (count == inputTargets.length){
      this.divContainerTarget.classList.remove('border-2', 'border-l-4', 'border-solid', 'border-yellow-500', );
        this.quantityChangedLabelTarget.classList.add('hidden');
        this.isQuantityChangedTarget.value = '';
    }
  }

  handleChange(event){
    const value = event.target.value
    if (value !== '') {
      if (parseFloat(value) > parseFloat(event.target.dataset.initialValue)){
        this.increment_change();
      } else {
        this.decrement_change();
      }
    }
  }
}
