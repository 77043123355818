import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['numOfCustomers', 'planType']

  connect() {

  }

  updateNumOfCustomersAndPlanType(event) {
    const selectedValue = event.target.value;

    if (selectedValue === 'Web App') {
      this.numOfCustomersTarget.classList.add("hidden");
      if(this.hasPlanTypeTarget){
        this.updatePlanTypeForWebApp();
      }
    } else {
      this.numOfCustomersTarget.classList.remove("hidden");
      if(this.hasPlanTypeTarget){
        this.updatePlanTypeForMobileApp();
      }
    }
  }

  updatePlanTypeForWebApp() {
    // Add "Quarterly" and "Half Yearly" to plan type options for Web App
    this.planTypeTarget.innerHTML = `
      <option value="0">Monthly</option>
      <option value="2">Quarterly</option>
      <option value="3">Half Yearly</option>
      <option value="1">Yearly</option>
    `;
  }

  updatePlanTypeForMobileApp() {
    // Only show "Monthly" and "Yearly" for Mobile App
    this.planTypeTarget.innerHTML = `
      <option value="0">Monthly</option>
      <option value="1">Yearly</option>
    `;
  }
}
