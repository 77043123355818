import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report-page-size"
export default class extends Controller {
  static targets=['pageSizeContainer']
  
  togglePageSize(event){
    let allowPageSizeReports = ['invoice_summary', 'invoice_detail', 'invoice_summary_v2', 'invoice_summary_v3']
    if(allowPageSizeReports.includes(event.target.value)){
      this.pageSizeContainerTarget.classList.remove("hidden");
    } else {
      this.pageSizeContainerTarget.classList.add("hidden");
    }
  }
}
